import React from 'react';
import './Styles.css'; // Import the existing CSS
import Logo from '../assets/images/logo.jpg';
import { useNavigate } from "react-router-dom";

const SideMenu = () => {

  const navigate = useNavigate();

  function logoutAdmin()
  {
    localStorage.removeItem('token');
    navigate('/Admin78k7h1Nd95F');
  }

  function navigateTo(location)
  {
    console.log(location);
    navigate(location);
  }

  return (
    <div className="side-menu">
      <div className="logo-container">
        <img src={Logo} alt="Logo" className="logo" />
        <p className="admin-text">Welcome, Admin</p>
      </div>
      <ul className="menu-list">
        {/* <li>Dashboard</li> */}
        <li onClick={()=>navigateTo('/Admin/AllEnquiries')}>All Enquiries</li>
        {/* <li>Settings</li> */}
        <li onClick={logoutAdmin}>Logout</li>
      </ul>
    </div>
  );
};

export default SideMenu;
