import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from '../component/Home';
import RegisterPage from '../component/RegisterPage';
import Details from '../component/Details';
import AddressDetails from '../component/AddressDetails';
import Finalstep from '../component/Finalstep';
import AdminLogin from '../backend/AdminLogin';
import Enquiry from '../backend/Enquiry';
import EnquiryDetails from '../backend/EnquiryDetails';
const Navigation = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/RegisterPage' element={<RegisterPage />} />
                <Route path='/Details' element={<Details />} />
                <Route path='/AddressDetails' element={<AddressDetails />} />
                <Route path='/Finalstep' element={<Finalstep />} />

                <Route path='/Admin78k7h1Nd95F' element={<AdminLogin />} />
                <Route path='/Admin/AllEnquiries' element={<Enquiry />} />
                <Route path='/Admin/Enquiry-details' element={<EnquiryDetails/>} />
            </Routes>
        </BrowserRouter>

    )
}
export default Navigation;