import React, { createContext,useState } from 'react';
import './App.css';
import Navigation from './navigation/Navigation';

export const FormContext = createContext({});


const App = () => {

  const [formData, setFormData] = useState({});
  return (
    <FormContext.Provider value={{formData,setFormData}}>
      <Navigation />
    </FormContext.Provider>

  )
}
export default App;