// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';  // Import the navigation hook
// import SideMenu from './SideMenu';  // Import the SideMenu component
// import TopHead from './TopHeader';    // Import the TopHead component
// import './Styles.css';              // Import your CSS file

// export default function Enquiry() {
//   const [enquiry, setEnquiry] = useState([]);
//   const [modalVisible, setModalVisible] = useState(false); // State to control modal visibility
//   const [selectedRecord, setSelectedRecord] = useState(null); // State to store selected record data
//   const navigate = useNavigate();  // Get the navigate function

//   useEffect(() => {
//     const token = localStorage.getItem('token');

//     if (!token) {
//       navigate('/Admin78k7h1Nd95F');  // If no token, redirect to login
//       return;
//     }

//     axios
//       .get("https://cleancarclaims.co.uk/mongo/api/cardetails", {
//         headers: {
//           Authorization: `Bearer ${token}`,  // Send the token in the request header
//         },
//       })
//       .then((response) => {
//         setEnquiry(response.data); // Store the response data into 'enquiry'
//       })
//       .catch((error) => {
//         console.error(error);
//         if (error.response.status === 403) {
//           navigate('/Admin78k7h1Nd95F');  // Redirect to login if unauthorized
//         }
//       });
//   }, [navigate]); // Add `navigate` as a dependency to the effect

//   // Handle View button click
//   const handleView = (record) => {
//     navigate('/Admin/Enquiry-details', { state: { record } }); // Pass the selected record as state
//   };

//   // Handle closing the modal
//   const handleClose = () => {
//     setModalVisible(false); // Hide the modal
//     setSelectedRecord(null); // Clear the selected record
//   };

//   return (
//     <div className="enquiry-page">
//       <SideMenu /> {/* Render the Side Menu */}
//       <div className="main-content">
//         <TopHead divStyles={{marginRight: '50px'}}>All Enquiries</TopHead> {/* Render the Top Header */}
        
//         <div className="table-container">
//           <table>
//             <thead>
//               <tr>
//                 <th>#</th>
//                 <th>Car Model</th>
//                 <th>Date</th>
//                 <th>Name</th>
//                 <th>Phone</th>
//                 <th>Email</th>
//                 <th>Payment</th>
//                 <th>Action</th>
//               </tr>
//             </thead>
//             <tbody>
//               {enquiry.length > 0 ? (
//                 enquiry.map((item, index) => (
//                   <tr key={item._id}>
//                     <td>{index + 1}</td>
//                     <td>{item.carmodal}</td>
//                     <td>{item.date}</td>
//                     <td>{item.title} {item.fname} {item.lname}</td>
//                     <td>{item.mobile}</td>
//                     <td>{item.email}</td>
//                     <td>{item.pay}</td>
//                     <td>
//                       <button className="action-btn" onClick={() => handleView(item)}>View</button>
//                     </td>
//                   </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan="7" style={{ textAlign: 'center' }}>No Enquiries are submitted</td>
//                 </tr>
//               )}
//             </tbody>
//           </table>

//         </div>

//         {/* Modal for showing detailed info */}
//         {modalVisible && selectedRecord && (
//           <div className="modal">
//             <div className="modal-content">
//               <span className="close" onClick={handleClose}>&times;</span>
//               <h2>Details for {selectedRecord.title} {selectedRecord.fname} {selectedRecord.lname}</h2>
//               <p><strong>Car Model:</strong> {selectedRecord.carmodal}</p>
//               <p><strong>Date:</strong> {selectedRecord.date}</p>
//               <p><strong>Phone:</strong> {selectedRecord.mobile}</p>
//               <p><strong>Email:</strong> {selectedRecord.email}</p>
//               <p><strong>Payment:</strong> {selectedRecord.pay}</p>
//               <p><strong>Postcode:</strong> {selectedRecord.postcode}</p>
//               <p><strong>Street Address:</strong> {selectedRecord.streetAddress}</p>
//               <p><strong>Address Line:</strong> {selectedRecord.addressline}</p>
//               <p><strong>City:</strong> {selectedRecord.city}</p>
//               <p><strong>Country:</strong> {selectedRecord.country}</p>
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SideMenu from './SideMenu';
import TopHead from './TopHeader';
import './Styles.css';

export default function Enquiry() {
  const [enquiry, setEnquiry] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const [totalPages, setTotalPages] = useState(1); // State for total pages
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      navigate('/Admin78k7h1Nd95F');
      return;
    }

    axios
      .get("https://cleancarclaims.co.uk/mongo/api/cardetails", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page: currentPage,
          limit: 10, // You can adjust the limit as needed
        },
      })
      .then((response) => {
        setEnquiry(response.data.records);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === 403) {
          navigate('/Admin78k7h1Nd95F');
        }
      });
  }, [navigate, currentPage]); // Add `currentPage` as a dependency

  const handleView = (record) => {
    navigate('/Admin/Enquiry-details', { state: { record } });
  };

  const handleClose = () => {
    setModalVisible(false);
    setSelectedRecord(null);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="enquiry-page">
      <SideMenu />
      <div className="main-content">
        <TopHead divStyles={{ marginRight: '50px' }}>All Enquiries</TopHead>
        
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Car Model</th>
                <th>Date</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Payment</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {enquiry.length > 0 ? (
                enquiry.map((item, index) => (
                  <tr key={item._id}>
                    <td>{(currentPage - 1) * 10 + index + 1}</td>
                    <td>{item.carmodal}</td>
                    <td>{item.date}</td>
                    <td>{item.title} {item.fname} {item.lname}</td>
                    <td>{item.mobile}</td>
                    <td>{item.email}</td>
                    <td>{item.pay}</td>
                    <td>
                      <button className="action-btn" onClick={() => handleView(item)}>View</button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" style={{ textAlign: 'center' }}>No Enquiries are submitted</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination Controls */}
        <div className="pagination">
          <button
            className="pagination-btn"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span>Page {currentPage} of {totalPages}</span>
          <button
            className="pagination-btn"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>

        {modalVisible && selectedRecord && (
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={handleClose}>&times;</span>
              <h2>Details for {selectedRecord.title} {selectedRecord.fname} {selectedRecord.lname}</h2>
              <p><strong>Car Model:</strong> {selectedRecord.carmodal}</p>
              <p><strong>Date:</strong> {selectedRecord.date}</p>
              <p><strong>Phone:</strong> {selectedRecord.mobile}</p>
              <p><strong>Email:</strong> {selectedRecord.email}</p>
              <p><strong>Payment:</strong> {selectedRecord.pay}</p>
              <p><strong>Postcode:</strong> {selectedRecord.postcode}</p>
              <p><strong>Street Address:</strong> {selectedRecord.streetAddress}</p>
              <p><strong>Address Line:</strong> {selectedRecord.addressline}</p>
              <p><strong>City:</strong> {selectedRecord.city}</p>
              <p><strong>Country:</strong> {selectedRecord.country}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

