import React, { useState } from 'react';
import axios from 'axios';
import './Styles.css'; // Updated CSS file for better styling
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    axios.post('https://cleancarclaims.co.uk/mongo/api/login', {
      email: email,
      password: password
    })
    .then(response => {
      const { token } = response.data;
      localStorage.setItem('token', token); // Store the token in localStorage
      navigate('/Admin/AllEnquiries');
    })
    .catch(error => {
      setError(error);
      console.error('Login failed:', error);
    });
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h1>Admin Login</h1>
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit">Login</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
