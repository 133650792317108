import React, { useState, useEffect, useContext, } from "react";
import person from "../assets/images/person-icon.png";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.jpg";
import carpicture from "../assets/images/carpicture.jpg";
import 'react-phone-number-input/style.css';
import { FormContext } from "../App";
import CountryDropdown from './CountryDropdown';

const Details = () => {
  const [title, settitle] = useState("");
  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [mobileError, setmobileError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [fnameError, setfnameError] = useState("");
  const [lnameError, setLnameError] = useState("");
  const [dobError, setDobError] = useState("");
  const { formData, setFormData } = useContext(FormContext);

  const navigate = useNavigate();
  const [confirmEmailError, setConfirmEmailError] = useState("");

  const handleCountryChange = selected => {
    setSelectedCountry(selected);
  };

  useEffect(() => {
    if (formData) {
      const { title, fname, lname, mobile, email, selectedDay, selectedMonth, selectedYear, confirmEmail } = formData;
      settitle(title);
      setfname(fname);
      setlname(lname);
      setmobile(mobile);
      setemail(email);
      setSelectedDay(selectedDay);
      setSelectedMonth(selectedMonth);
      setSelectedYear(selectedYear);
      setConfirmEmail(confirmEmail)
    }
  }, [formData])


  const handleFnameChange = (e) => {
    const value = e.target.value;
    setfname(value);
  };

  const handleLnameChange = (e) => {
    const value = e.target.value;
    setlname(value);
  };

  const handleButtonClick = () => {
    console.log('selectedCountry', selectedCountry)
    let valid = true;

    if (!title) {
      setTitleError("Title is required.");
      valid = false;
    } else {
      setTitleError("");
    }

    if (!fname) {
      setfnameError("First name is required.");
      valid = false;
    } else {
      setfnameError("");
    }

    if (!lname) {
      setLnameError("Last name is required.");
      valid = false;
    } else {
      setLnameError("");
    }
    if (!mobile) {
      setmobileError("Mobile Number is required.");
      valid = false;
    } else {
      setmobileError("");
    }

    if (!selectedDay || !selectedMonth || !selectedYear) {
      setDobError("Date of birth is required.");
      valid = false;
    } else {
      setDobError("");
    }

    if (email !== confirmEmail) {
      setEmailError("Emails do not match");
      valid = false;
    }

    if (valid) {
      setIsLoading(true);
      setFormData({ ...formData, title, fname, lname, mobile, email, selectedDay, selectedMonth, selectedYear, confirmEmail, selectedCountry })
      console.log('Detailpageformdata', formData);
      setTimeout(() => {
        setIsLoading(false);
        navigate("/AddressDetails", { state: formData });
      }, 1000);
    }
  };
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setemail(newEmail);

    if (!emailPattern.test(newEmail)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }

    if (newEmail === confirmEmail) {
      setConfirmEmailError("");
    } else {
      setConfirmEmailError("Emails do not match");
    }
  };

  const handleConfirmEmailChange = (e) => {
    const newConfirmEmail = e.target.value;
    setConfirmEmail(newConfirmEmail);
    if (email === newConfirmEmail) {
      setConfirmEmailError("");
    } else {
      setConfirmEmailError("Emails do not match");
    }
  };
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const years = Array.from(
    { length: 100 },
    (_, i) => new Date().getFullYear() - i
  );

  const handleDayChange = (e) => {
    const value = e.target.value;
    setSelectedDay(value);

    if (value && selectedMonth && selectedYear) {
      setDobError("");
    } else {
      setDobError("Date of birth is required.");
    }
  };

  const handleMonthChange = (e) => {
    const value = e.target.value;
    setSelectedMonth(value);

    if (selectedDay && value && selectedYear) {
      setDobError("");
    } else {
      setDobError("Date of birth is required.");
    }
  };

  const handleYearChange = (e) => {
    const value = e.target.value;
    setSelectedYear(value);

    if (selectedDay && selectedMonth && value) {
      setDobError("");
    } else {
      setDobError("Date of birth is required.");
    }
  };


  return (
    <div className="maincontainer">
      <div className="logodiv">
        <img src={logo} className="logo1" alt="" />
      </div>
      <div className="yellowdiv"></div>
      <div className="carpicturediv">
        <img src={carpicture} className="carpicture" alt="carpicture" />
      </div>
      <div className="detailMainContainer">
        <div className="progress-container">
          <p className="step-indicator">Step 1 of 3</p>
          <div className="progress-bar">
            <div className="progress" style={{ width: '33%' }}>33%</div>
          </div>
        </div>
        <div className="detailcontainer">
          <img src={person} className="personimg" alt="person" />
          <p className="detailsText2">Your Details</p>
        </div>
        <div className="good-news-div">
          <p>Good news!</p>
          <p>
            We have matched your vehicles to our records, complete this short
            form to progress your claim.
          </p>
        </div>
        <div className="three-input">
          <div className="inputsdiv">
            <div className="firstnameText">
              <p className="titleText">Title<span className="required-star">*</span></p>
            </div>
            <select
              className="dropdown"
              value={title}
              onChange={(e) => {
                const selectedTitle = e.target.value;
                settitle(selectedTitle);
                if (selectedTitle) {
                  setTitleError("");
                }
                else {
                  setTitleError("Title require");
                }
              }}
            >
              <option value=""> Title</option>
              <option value="Mr">Mr</option>
              <option value="Ms">Ms</option>
              <option value="Mrs">Mrs</option>
              <option value="Dr">Dr</option>
            </select>
            {titleError && <p style={{ color: "red" }}>{titleError}</p>}
          </div>
          <div className="inputsdiv">
            <div className="firstnameText">
              <p className="titleText ">Full Name<span className="required-star">*</span></p>
            </div>
            <input
              value={fname}
              onChange={handleFnameChange}

              className="title-Input name-Input"
              placeholder="First name"
            />
            {fnameError && <p className="fnameError" style={{ color: "red" }}>{fnameError}</p>}

          </div>
          <div className="inputsdiv">
            <div className="firstnameText">

              <p className="titleTextlastname">last Name</p>
            </div>
            <input
              value={lname}
              onChange={handleLnameChange}

              className="title-Input2"
              placeholder="Last name"
            />

            {lnameError && <p className="fnameError" style={{ color: "red" }}>{lnameError}</p>}
          </div>
        </div>
        <div className="mobilediv">
          <p className="titleText ">Mobile Number<span className="required-star">*</span></p>
          <div className="mobileinputmaindiv">
            <div className="dropdowncountry">
              <CountryDropdown onChange={handleCountryChange} value={selectedCountry} style={{ backgroundColor: '#f2f2f2' }} />
            </div>
            <div className="mobileinputdiv">
              <input
                type="number"
                value={mobile}
                onChange={(e) => setmobile(e.target.value)} placeholder="Mobile" className="mobileinput" 
              />
            </div>
          </div>

        </div>
        {mobileError && <p className="fnameError" style={{ color: "red" }}>{mobileError}</p>}

        <div className="mobilediv">
          <p className="titleText ">Email Address<span className="required-star">*</span></p>
          <div className="emaildiv">
            <div className="inputerror inputerror_error_text">
              <input
                value={email}
                onChange={handleEmailChange}
                className="conformemail-Input"
                placeholder="Email"
              />

              {emailError && <p className="fnameError" style={{ color: "red", }}>{emailError}</p>}
            </div>

            <div className="inputerror inputerror_error_text">
              <input
                value={confirmEmail}
                onChange={handleConfirmEmailChange}
                className="conformemail-Input"
                placeholder="Confirm email"
              />
              {confirmEmailError && <p className="fnameError" style={{ color: "red" }}>{confirmEmailError}</p>}
            </div>
          </div>
        </div>
        <div className="mobilediv">
          <p className="titleText ">Date Of Birth<span className="required-star">*</span></p>
          <div className="datediv">
            <select
              className="daydiv"
              value={selectedDay}
              onChange={handleDayChange}
            >
              <option value="" disabled>
                Day
              </option>
              {days.map((day) => (
                <option key={day} value={day}>
                  {day}
                </option>
              ))}
            </select>

            <select
              className="daydiv"
              value={selectedMonth}
              onChange={handleMonthChange}
            >
              <option value="" disabled>
                Month
              </option>
              {months.map((month, index) => (
                <option key={index} value={index + 1}>
                  {month}
                </option>
              ))}
            </select>

            <select
              className="daydiv"
              value={selectedYear}
              onChange={handleYearChange}
            >
              <option value="" disabled>
                Year
              </option>
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
          {dobError && <p style={{ color: "red" }}>{dobError}</p>}

        </div>
        <div className="buttoncontainer">
          <button
            onClick={() => handleButtonClick()}
            className="continuebutton"
          >
            {isLoading ? <div className="loader"></div> : <p className="continuetext" style={{ marginTop: 0 }}>Next</p>}
          </button>
        </div>
      </div>
      <div className="yellowdiv"></div>
    </div>
  );
};
export default Details;

