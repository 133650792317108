import React from 'react';
import './Styles.css'; // Import the existing CSS

const TopHeader = ({divStyles, children}) => {
  return (
    <div style={divStyles}>
      <h2>{children}</h2>
    </div>
  );
};

export default TopHeader;
