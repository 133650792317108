import React from 'react';
import Select from 'react-select';
import Flag from 'react-world-flags';

const countryOptions = [
  { value: 'AF', label: 'Afghanistan', code: '+93', flag: 'AF' },
  { value: 'AL', label: 'Albania', code: '+355', flag: 'AL' },
  { value: 'DZ', label: 'Algeria', code: '+213', flag: 'DZ' },
  { value: 'AS', label: 'American Samoa', code: '+1-684', flag: 'AS' },
  { value: 'AD', label: 'Andorra', code: '+376', flag: 'AD' },
  { value: 'AO', label: 'Angola', code: '+244', flag: 'AO' },
  { value: 'AI', label: 'Anguilla', code: '+1-264', flag: 'AI' },
  { value: 'AQ', label: 'Antarctica', code: '+672', flag: 'AQ' },
  { value: 'AG', label: 'Antigua and Barbuda', code: '+1-268', flag: 'AG' },
  { value: 'AR', label: 'Argentina', code: '+54', flag: 'AR' },
  { value: 'AM', label: 'Armenia', code: '+374', flag: 'AM' },
  { value: 'AW', label: 'Aruba', code: '+297', flag: 'AW' },
  { value: 'AU', label: 'Australia', code: '+61', flag: 'AU' },
  { value: 'AT', label: 'Austria', code: '+43', flag: 'AT' },
  { value: 'AZ', label: 'Azerbaijan', code: '+994', flag: 'AZ' },
  { value: 'BS', label: 'Bahamas', code: '+1-242', flag: 'BS' },
  { value: 'BH', label: 'Bahrain', code: '+973', flag: 'BH' },
  { value: 'BD', label: 'Bangladesh', code: '+880', flag: 'BD' },
  { value: 'BB', label: 'Barbados', code: '+1-246', flag: 'BB' },
  { value: 'BY', label: 'Belarus', code: '+375', flag: 'BY' },
  { value: 'BE', label: 'Belgium', code: '+32', flag: 'BE' },
  { value: 'BZ', label: 'Belize', code: '+501', flag: 'BZ' },
  { value: 'BJ', label: 'Benin', code: '+229', flag: 'BJ' },
  { value: 'BM', label: 'Bermuda', code: '+1-441', flag: 'BM' },
  { value: 'BT', label: 'Bhutan', code: '+975', flag: 'BT' },
  { value: 'BO', label: 'Bolivia', code: '+591', flag: 'BO' },
  { value: 'BA', label: 'Bosnia and Herzegovina', code: '+387', flag: 'BA' },
  { value: 'BW', label: 'Botswana', code: '+267', flag: 'BW' },
  { value: 'BR', label: 'Brazil', code: '+55', flag: 'BR' },
  { value: 'IO', label: 'British Indian Ocean Territory', code: '+246', flag: 'IO' },
  { value: 'VG', label: 'British Virgin Islands', code: '+1-284', flag: 'VG' },
  { value: 'BN', label: 'Brunei', code: '+673', flag: 'BN' },
  { value: 'BG', label: 'Bulgaria', code: '+359', flag: 'BG' },
  { value: 'BF', label: 'Burkina Faso', code: '+226', flag: 'BF' },
  { value: 'BI', label: 'Burundi', code: '+257', flag: 'BI' },
  { value: 'KH', label: 'Cambodia', code: '+855', flag: 'KH' },
  { value: 'CM', label: 'Cameroon', code: '+237', flag: 'CM' },
  { value: 'CA', label: 'Canada', code: '+1', flag: 'CA' },
  { value: 'CV', label: 'Cape Verde', code: '+238', flag: 'CV' },
  { value: 'KY', label: 'Cayman Islands', code: '+1-345', flag: 'KY' },
  { value: 'CF', label: 'Central African Republic', code: '+236', flag: 'CF' },
  { value: 'TD', label: 'Chad', code: '+235', flag: 'TD' },
  { value: 'CL', label: 'Chile', code: '+56', flag: 'CL' },
  { value: 'CN', label: 'China', code: '+86', flag: 'CN' },
  { value: 'CX', label: 'Christmas Island', code: '+61', flag: 'CX' },
  { value: 'CC', label: 'Cocos Islands', code: '+61', flag: 'CC' },
  { value: 'CO', label: 'Colombia', code: '+57', flag: 'CO' },
  { value: 'KM', label: 'Comoros', code: '+269', flag: 'KM' },
  { value: 'CK', label: 'Cook Islands', code: '+682', flag: 'CK' },
  { value: 'CR', label: 'Costa Rica', code: '+506', flag: 'CR' },
  { value: 'HR', label: 'Croatia', code: '+385', flag: 'HR' },
  { value: 'CU', label: 'Cuba', code: '+53', flag: 'CU' },
  { value: 'CW', label: 'Curacao', code: '+599', flag: 'CW' },
  { value: 'CY', label: 'Cyprus', code: '+357', flag: 'CY' },
  { value: 'CZ', label: 'Czech Republic', code: '+420', flag: 'CZ' },
  { value: 'CD', label: 'Democratic Republic of the Congo', code: '+243', flag: 'CD' },
  { value: 'DK', label: 'Denmark', code: '+45', flag: 'DK' },
  { value: 'DJ', label: 'Djibouti', code: '+253', flag: 'DJ' },
  { value: 'DM', label: 'Dominica', code: '+1-767', flag: 'DM' },
  { value: 'DO', label: 'Dominican Republic', code: '+1-809', flag: 'DO' },
  { value: 'TL', label: 'East Timor', code: '+670', flag: 'TL' },
  { value: 'EC', label: 'Ecuador', code: '+593', flag: 'EC' },
  { value: 'EG', label: 'Egypt', code: '+20', flag: 'EG' },
  { value: 'SV', label: 'El Salvador', code: '+503', flag: 'SV' },
  { value: 'GQ', label: 'Equatorial Guinea', code: '+240', flag: 'GQ' },
  { value: 'ER', label: 'Eritrea', code: '+291', flag: 'ER' },
  { value: 'EE', label: 'Estonia', code: '+372', flag: 'EE' },
  { value: 'ET', label: 'Ethiopia', code: '+251', flag: 'ET' },
  { value: 'FK', label: 'Falkland Islands', code: '+500', flag: 'FK' },
  { value: 'FO', label: 'Faroe Islands', code: '+298', flag: 'FO' },
  { value: 'FJ', label: 'Fiji', code: '+679', flag: 'FJ' },
  { value: 'FI', label: 'Finland', code: '+358', flag: 'FI' },
  { value: 'FR', label: 'France', code: '+33', flag: 'FR' },
  { value: 'PF', label: 'French Polynesia', code: '+689', flag: 'PF' },
  { value: 'GA', label: 'Gabon', code: '+241', flag: 'GA' },
  { value: 'GM', label: 'Gambia', code: '+220', flag: 'GM' },
  { value: 'GE', label: 'Georgia', code: '+995', flag: 'GE' },
  { value: 'DE', label: 'Germany', code: '+49', flag: 'DE' },
  { value: 'GH', label: 'Ghana', code: '+233', flag: 'GH' },
  { value: 'GI', label: 'Gibraltar', code: '+350', flag: 'GI' },
  { value: 'GR', label: 'Greece', code: '+30', flag: 'GR' },
  { value: 'GL', label: 'Greenland', code: '+299', flag: 'GL' },
  { value: 'GD', label: 'Grenada', code: '+1-473', flag: 'GD' },
  { value: 'GU', label: 'Guam', code: '+1-671', flag: 'GU' },
  { value: 'GT', label: 'Guatemala', code: '+502', flag: 'GT' },
  { value: 'GN', label: 'Guinea', code: '+224', flag: 'GN' },
  { value: 'GW', label: 'Guinea-Bissau', code: '+245', flag: 'GW' },
  { value: 'GY', label: 'Guyana', code: '+592', flag: 'GY' },
  { value: 'HT', label: 'Haiti', code: '+509', flag: 'HT' },
  { value: 'HN', label: 'Honduras', code: '+504', flag: 'HN' },
  { value: 'HK', label: 'Hong Kong', code: '+852', flag: 'HK' },
  { value: 'HU', label: 'Hungary', code: '+36', flag: 'HU' },
  { value: 'IS', label: 'Iceland', code: '+354', flag: 'IS' },
  { value: 'IN', label: 'India', code: '+91', flag: 'IN' },
  { value: 'ID', label: 'Indonesia', code: '+62', flag: 'ID' },
  { value: 'IR', label: 'Iran', code: '+98', flag: 'IR' },
  { value: 'IQ', label: 'Iraq', code: '+964', flag: 'IQ' },
  { value: 'IE', label: 'Ireland', code: '+353', flag: 'IE' },
  { value: 'IM', label: 'Isle of Man', code: '+44-1624', flag: 'IM' },
  { value: 'IL', label: 'Israel', code: '+972', flag: 'IL' },
  { value: 'IT', label: 'Italy', code: '+39', flag: 'IT' },
  { value: 'CI', label: 'Ivory Coast', code: '+225', flag: 'CI' },
  { value: 'JM', label: 'Jamaica', code: '+1-876', flag: 'JM' },
  { value: 'JP', label: 'Japan', code: '+81', flag: 'JP' },
  { value: 'JO', label: 'Jordan', code: '+962', flag: 'JO' },
  { value: 'KZ', label: 'Kazakhstan', code: '+7', flag: 'KZ' },
  { value: 'KE', label: 'Kenya', code: '+254', flag: 'KE' },
  { value: 'KI', label: 'Kiribati', code: '+686', flag: 'KI' },
  { value: 'XK', label: 'Kosovo', code: '+383', flag: 'XK' },
  { value: 'KW', label: 'Kuwait', code: '+965', flag: 'KW' },
  { value: 'KG', label: 'Kyrgyzstan', code: '+996', flag: 'KG' },
  { value: 'LA', label: 'Laos', code: '+856', flag: 'LA' },
  { value: 'LV', label: 'Latvia', code: '+371', flag: 'LV' },
  { value: 'LB', label: 'Lebanon', code: '+961', flag: 'LB' },
  { value: 'LS', label: 'Lesotho', code: '+266', flag: 'LS' },
  { value: 'LR', label: 'Liberia', code: '+231', flag: 'LR' },
  { value: 'LY', label: 'Libya', code: '+218', flag: 'LY' },
  { value: 'LI', label: 'Liechtenstein', code: '+423', flag: 'LI' },
  { value: 'LT', label: 'Lithuania', code: '+370', flag: 'LT' },
  { value: 'LU', label: 'Luxembourg', code: '+352', flag: 'LU' },
  { value: 'MO', label: 'Macau', code: '+853', flag: 'MO' },
  { value: 'MK', label: 'North Macedonia', code: '+389', flag: 'MK' },
  { value: 'MG', label: 'Madagascar', code: '+261', flag: 'MG' },
  { value: 'MW', label: 'Malawi', code: '+265', flag: 'MW' },
  { value: 'MY', label: 'Malaysia', code: '+60', flag: 'MY' },
  { value: 'MV', label: 'Maldives', code: '+960', flag: 'MV' },
  { value: 'ML', label: 'Mali', code: '+223', flag: 'ML' },
  { value: 'MT', label: 'Malta', code: '+356', flag: 'MT' },
  { value: 'MH', label: 'Marshall Islands', code: '+692', flag: 'MH' },
  { value: 'MR', label: 'Mauritania', code: '+222', flag: 'MR' },
  { value: 'MU', label: 'Mauritius', code: '+230', flag: 'MU' },
  { value: 'YT', label: 'Mayotte', code: '+262', flag: 'YT' },
  { value: 'MX', label: 'Mexico', code: '+52', flag: 'MX' },
  { value: 'FM', label: 'Micronesia', code: '+691', flag: 'FM' },
  { value: 'MD', label: 'Moldova', code: '+373', flag: 'MD' },
  { value: 'MC', label: 'Monaco', code: '+377', flag: 'MC' },
  { value: 'MN', label: 'Mongolia', code: '+976', flag: 'MN' },
  { value: 'ME', label: 'Montenegro', code: '+382', flag: 'ME' },
  { value: 'MS', label: 'Montserrat', code: '+1-664', flag: 'MS' },
  { value: 'MA', label: 'Morocco', code: '+212', flag: 'MA' },
  { value: 'MZ', label: 'Mozambique', code: '+258', flag: 'MZ' },
  { value: 'MM', label: 'Myanmar', code: '+95', flag: 'MM' },
  { value: 'NA', label: 'Namibia', code: '+264', flag: 'NA' },
  { value: 'NR', label: 'Nauru', code: '+674', flag: 'NR' },
  { value: 'NP', label: 'Nepal', code: '+977', flag: 'NP' },
  { value: 'NL', label: 'Netherlands', code: '+31', flag: 'NL' },
  { value: 'AN', label: 'Netherlands Antilles', code: '+599', flag: 'AN' },
  { value: 'NC', label: 'New Caledonia', code: '+687', flag: 'NC' },
  { value: 'NZ', label: 'New Zealand', code: '+64', flag: 'NZ' },
  { value: 'NI', label: 'Nicaragua', code: '+505', flag: 'NI' },
  { value: 'NE', label: 'Niger', code: '+227', flag: 'NE' },
  { value: 'NG', label: 'Nigeria', code: '+234', flag: 'NG' },
  { value: 'NU', label: 'Niue', code: '+683', flag: 'NU' },
  { value: 'NF', label: 'Norfolk Island', code: '+672', flag: 'NF' },
  { value: 'KP', label: 'North Korea', code: '+850', flag: 'KP' },
  { value: 'MP', label: 'Northern Mariana Islands', code: '+1-670', flag: 'MP' },
  { value: 'NO', label: 'Norway', code: '+47', flag: 'NO' },
  { value: 'OM', label: 'Oman', code: '+968', flag: 'OM' },
  { value: 'PK', label: 'Pakistan', code: '+92', flag: 'PK' },
  { value: 'PW', label: 'Palau', code: '+680', flag: 'PW' },
  { value: 'PA', label: 'Panama', code: '+507', flag: 'PA' },
  { value: 'PG', label: 'Papua New Guinea', code: '+675', flag: 'PG' },
  { value: 'PY', label: 'Paraguay', code: '+595', flag: 'PY' },
  { value: 'PE', label: 'Peru', code: '+51', flag: 'PE' },
  { value: 'PH', label: 'Philippines', code: '+63', flag: 'PH' },
  { value: 'PN', label: 'Pitcairn Islands', code: '+870', flag: 'PN' },
  { value: 'PL', label: 'Poland', code: '+48', flag: 'PL' },
  { value: 'PT', label: 'Portugal', code: '+351', flag: 'PT' },
  { value: 'PR', label: 'Puerto Rico', code: '+1-787', flag: 'PR' },
  { value: 'QA', label: 'Qatar', code: '+974', flag: 'QA' },
  { value: 'CG', label: 'Republic of the Congo', code: '+242', flag: 'CG' },
  { value: 'RE', label: 'Réunion', code: '+262', flag: 'RE' },
  { value: 'RO', label: 'Romania', code: '+40', flag: 'RO' },
  { value: 'RU', label: 'Russia', code: '+7', flag: 'RU' },
  { value: 'RW', label: 'Rwanda', code: '+250', flag: 'RW' },
  { value: 'BL', label: 'Saint Barthélemy', code: '+590', flag: 'BL' },
  { value: 'SH', label: 'Saint Helena', code: '+290', flag: 'SH' },
  { value: 'KN', label: 'Saint Kitts and Nevis', code: '+1-869', flag: 'KN' },
  { value: 'LC', label: 'Saint Lucia', code: '+1-758', flag: 'LC' },
  { value: 'MF', label: 'Saint Martin', code: '+590', flag: 'MF' },
  { value: 'PM', label: 'Saint Pierre and Miquelon', code: '+508', flag: 'PM' },
  { value: 'VC', label: 'Saint Vincent and the Grenadines', code: '+1-784', flag: 'VC' },
  { value: 'WS', label: 'Samoa', code: '+685', flag: 'WS' },
  { value: 'SM', label: 'San Marino', code: '+378', flag: 'SM' },
  { value: 'ST', label: 'São Tomé and Príncipe', code: '+239', flag: 'ST' },
  { value: 'SA', label: 'Saudi Arabia', code: '+966', flag: 'SA' },
  { value: 'SN', label: 'Senegal', code: '+221', flag: 'SN' },
  { value: 'RS', label: 'Serbia', code: '+381', flag: 'RS' },
  { value: 'SC', label: 'Seychelles', code: '+248', flag: 'SC' },
  { value: 'SL', label: 'Sierra Leone', code: '+232', flag: 'SL' },
  { value: 'SG', label: 'Singapore', code: '+65', flag: 'SG' },
  { value: 'SX', label: 'Sint Maarten', code: '+1-721', flag: 'SX' },
  { value: 'SK', label: 'Slovakia', code: '+421', flag: 'SK' },
  { value: 'SI', label: 'Slovenia', code: '+386', flag: 'SI' },
  { value: 'SB', label: 'Solomon Islands', code: '+677', flag: 'SB' },
  { value: 'SO', label: 'Somalia', code: '+252', flag: 'SO' },
  { value: 'ZA', label: 'South Africa', code: '+27', flag: 'ZA' },
  { value: 'KR', label: 'South Korea', code: '+82', flag: 'KR' },
  { value: 'SS', label: 'South Sudan', code: '+211', flag: 'SS' },
  { value: 'ES', label: 'Spain', code: '+34', flag: 'ES' },
  { value: 'LK', label: 'Sri Lanka', code: '+94', flag: 'LK' },
  { value: 'SD', label: 'Sudan', code: '+249', flag: 'SD' },
  { value: 'SR', label: 'Suriname', code: '+597', flag: 'SR' },
  { value: 'SJ', label: 'Svalbard and Jan Mayen', code: '+47', flag: 'SJ' },
  { value: 'SE', label: 'Sweden', code: '+46', flag: 'SE' },
  { value: 'CH', label: 'Switzerland', code: '+41', flag: 'CH' },
  { value: 'SY', label: 'Syria', code: '+963', flag: 'SY' },
  { value: 'TW', label: 'Taiwan', code: '+886', flag: 'TW' },
  { value: 'TJ', label: 'Tajikistan', code: '+992', flag: 'TJ' },
  { value: 'TZ', label: 'Tanzania', code: '+255', flag: 'TZ' },
  { value: 'TH', label: 'Thailand', code: '+66', flag: 'TH' },
  { value: 'TL', label: 'Timor-Leste', code: '+670', flag: 'TL' },
  { value: 'TG', label: 'Togo', code: '+228', flag: 'TG' },
  { value: 'TK', label: 'Tokelau', code: '+690', flag: 'TK' },
  { value: 'TO', label: 'Tonga', code: '+676', flag: 'TO' },
  { value: 'TT', label: 'Trinidad and Tobago', code: '+1-868', flag: 'TT' },
  { value: 'TN', label: 'Tunisia', code: '+216', flag: 'TN' },
  { value: 'TR', label: 'Turkey', code: '+90', flag: 'TR' },
  { value: 'TM', label: 'Turkmenistan', code: '+993', flag: 'TM' },
  { value: 'TC', label: 'Turks and Caicos Islands', code: '+1-649', flag: 'TC' },
  { value: 'TV', label: 'Tuvalu', code: '+688', flag: 'TV' },
  { value: 'UG', label: 'Uganda', code: '+256', flag: 'UG' },
  { value: 'UA', label: 'Ukraine', code: '+380', flag: 'UA' },
  { value: 'AE', label: 'United Arab Emirates', code: '+971', flag: 'AE' },
  { value: 'GB', label: 'United Kingdom', code: '+44', flag: 'GB' },
  { value: 'US', label: 'United States', code: '+1', flag: 'US' },
  { value: 'UY', label: 'Uruguay', code: '+598', flag: 'UY' },
  { value: 'UZ', label: 'Uzbekistan', code: '+998', flag: 'UZ' },
  { value: 'VU', label: 'Vanuatu', code: '+678', flag: 'VU' },
  { value: 'VA', label: 'Vatican City', code: '+39-06', flag: 'VA' },
  { value: 'VE', label: 'Venezuela', code: '+58', flag: 'VE' },
  { value: 'VN', label: 'Vietnam', code: '+84', flag: 'VN' },
  { value: 'WF', label: 'Wallis and Futuna', code: '+681', flag: 'WF' },
  { value: 'EH', label: 'Western Sahara', code: '+212', flag: 'EH' },
  { value: 'YE', label: 'Yemen', code: '+967', flag: 'YE' },
  { value: 'ZM', label: 'Zambia', code: '+260', flag: 'ZM' },
  { value: 'ZW', label: 'Zimbabwe', code: '+263', flag: 'ZW' },
];

const CountryOption = ({ data }) => (
  <div className='flagmaindiv'>
    <Flag code={data.flag} className='flag' />
    <span style={{ color: 'black' }}>{data.code}</span>
  </div>
);

const CountryDropdown = ({ onChange, value }) => (
  <Select
    options={countryOptions}
    getOptionLabel={e => <CountryOption data={e} />}
    getOptionValue={e => e.label}
    onChange={onChange}
    value={value || countryOptions.find(option => option.value === 'GB')}
    isSearchable
    placeholder='Select Country'
    styles={{
      control: (provided, state) => ({
        ...provided,
        backgroundColor: '#f2f2f2',  
        borderColor: '#f2f2f2',
        boxShadow: state.isFocused ? 'none' : provided.boxShadow, 

        '&:hover': {
          borderColor: '#f2f2f2', 
        },
      }),
      menu: (provided) => ({
        ...provided,
        backgroundColor: '#f2f2f2',
      }),
    
      option: (provided, { isFocused, isSelected }) => ({
        ...provided,
        backgroundColor: isSelected
          ? '#f2f2f2'
          : isFocused
            ? '#f2f2f2'
            : '#f2f2f2',
        color: 'black',
      }),
      singleValue: (provided) => ({
        ...provided,
        color: 'black',
      }),
    }}
  />
);

export default CountryDropdown;
