import React, { useState, useEffect, useContext, useMemo } from "react";
import home from "../assets/images/home-icon.png";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.jpg";
import carpicture from "../assets/images/carpicture.jpg";
import { FormContext } from "../App";
import countryList from 'react-select-country-list';

import Select from 'react-select';


const AddressDetails = () => {
  const options = useMemo(() => countryList().getData(), [])
  const [postcode, setPostcode] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [addressline, setAddressline] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState();
  const [postcodeError, setPostcodeError] = useState("");
  const [streetAddressError, setStreetAddressError] = useState("");
  const [cityError, setCityError] = useState("");
  const [countryError, setCountryError] = useState("");
  const { formData, setFormData } = useContext(FormContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingpre, setIsLoadingpre] = useState(false);

  useEffect(() => {
    const defaultCountry = countryList()?.getData()?.find(option => option.label === "United Kingdom");
    setCountry({ ...defaultCountry })
    console.log(defaultCountry)
  }, [])

  useEffect(() => {
    console.log(country)
  }, [country])


  const navigate = useNavigate();
  useEffect(() => {
    if (formData) {
      const { postcode, selectedCountry, streetAddress, addressline, city, } = formData;
      setPostcode(postcode);
      setStreetAddress(streetAddress);
      setAddressline(addressline);
      setCity(city);
      if (selectedCountry) {
        setCountry(selectedCountry);

      }
    }
  }, [formData]);

  const goPrevious = () => {
    setIsLoadingpre(true)
    setTimeout(() => {
      setIsLoadingpre(false); 
      navigate('/Details'
      );
    }, 200);
  };
  const changeHandler = (selectedOption) => {
    setCountry(selectedOption);
  };

  const handleFindAddress = async () => {
    if (!postcode) {
      setPostcodeError("Postcode is required.");
      return;
    }

    try {
      const apiKey = "AIzaSyDCp4AH-JU3vRmQOMdADT0nEBpZ5XcsgWA";
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${postcode}&key=${apiKey}`);
      const data = await response.json();
      console.log("Fetch Address:::::::::::", data);
      if (data.status === "OK" && data.results.length > 0) {
        const result = data.results[0];
        const addressComponents = result.address_components;

        const streetAddress = addressComponents.find(component =>
          component.types.includes("route") || component.types.includes("street_address")
        )?.long_name || "";

        const addressline = addressComponents.find(component =>
          component.types.includes("sublocality") || component.types.includes("neighborhood")
        )?.long_name || "";

        const city = addressComponents.find(component =>
          component.types.includes("locality") || component.types.includes("postal_town")
        )?.long_name || "";
        setStreetAddress(streetAddress);
        setAddressline(addressline);
        setCity(city);
        setPostcodeError("");
        setStreetAddressError("");
        setCityError("");
        setCountryError("");
      } else {
        const manualAddress = alert("Address not found. Please type address manually:");
        if (manualAddress) {
          setStreetAddress(manualAddress);
          setAddressline("");
          setCity("");
          setCountry("");
        }
      }
    } catch (error) {
      console.error("Error fetching address data:", error);
      setPostcodeError("Failed to fetch address data.");
    }
  };


  const handleInputChange = (setter, errorSetter, validationFn) => (e) => {
    const value = e.target.value;
    setter(value);
    errorSetter(validationFn(value));
  };

  const validateStreetAddress = (value) => {
    return !value ? "Street Address is required." : "";
  };

  const validateCity = (value) => {
    return !value ? "City is required." : "";
  };

  const handleButtonClick = () => {
    let valid = true;

    if (!postcode) {
      setPostcodeError("Postcode is required.");
      valid = false;
    }

    if (!streetAddress) {
      setStreetAddressError("Street Address is required.");
      valid = false;
    }

    if (!city) {
      setCityError("City is required.");
      valid = false;
    }

    if (valid) {
      console.log("country==", country)

      const selectedCountry = country ? country.label : "";
      setIsLoading(true);

      setFormData({
        ...formData,
        postcode,
        streetAddress,
        addressline,
        city,
        country: selectedCountry,
        selectedCountry: country
      });
      console.log('Detailpageformdata///////////2', formData);
      setTimeout(() => {
        setIsLoading(false);
        navigate("/Finalstep", { state: { ...formData, country: selectedCountry } });
      }, 1000);
    }
  };


  return (
    <div className="maincontainer">
      <div className="logodiv">
        <img src={logo} className="logo1" alt="logo" />
      </div>
      <div className="yellowdiv"></div>
      <div className="carpicturediv">
        <img src={carpicture} className="carpicture" alt="carpicture" />
      </div>
      <div className="detailMainContainer">
        <div className="progress-container">
          <p className="step-indicator">Step 2 of 3</p>
          <div className="progress-bar">
            <div className="progress" style={{ width: '66%' }}>66%</div>
          </div>
        </div>
        <div className="detailcontainer">
          <img src={home} className="personimg" alt="home" />
          <p className="detailsText2">Current Address</p>
        </div>
        <div className="addresssearchdiv">
          <p className="adddressSeachtext">Address Search<span className="required-star">*</span></p>
        </div>
        <div className="mobilediv addressdiv">
          <div className="postcode-Inputdiv">
            <input
              value={postcode}
              onChange={(e) => setPostcode(e.target.value)}
              className="postcode-Input"
              placeholder="Postcode"
            />
            {postcodeError && <p style={{ color: "red" }}>{postcodeError}</p>}
          </div>
          <button className="findbutton" onClick={handleFindAddress}>
            <p className="findtext" style={{ marginTop: 0 }}>Find Address</p>
          </button>
        </div>
        <div className="mobilediv addressdiv">
          <div className="mobile-Input-div">
            <input
              value={streetAddress}
              onChange={handleInputChange(setStreetAddress, setStreetAddressError, validateStreetAddress)}
              className="street-Input"
              placeholder="Street Address"
            />
            {streetAddressError && <p style={{ color: "red" }}>{streetAddressError}</p>}
          </div>
        </div>
        <div className="mobilediv addressdiv">
          <div className="mobile-Input-div">
            <input
              value={addressline}
              onChange={(e) => setAddressline(e.target.value)}
              className="street-Input"
              placeholder="Address Line 2"
            />
          </div>
        </div>
        <div className="mobilediv citypostcodediv">
          <div className="city-Input-div">
            <input
              value={city}
              onChange={handleInputChange(setCity, setCityError, validateCity)}
              className="cityinput"
              placeholder="City"
            />
            {cityError && <p style={{ color: "red" }}>{cityError}</p>}
          </div>
          <div className="city-Input-div">
            <input
              value={postcode}
              onChange={(e) => setPostcode(e.target.value)}
              className="cityinput"
              placeholder="Postcode"
            />
            {postcodeError && <p style={{ color: "red" }}>{postcodeError}</p>}

          </div>

        </div>
        <div className="mobilediv addressdiv">
          <div className="country selectCountry">
            <Select options={options} value={country} onChange={changeHandler} placeholder="Select Country" styles={{
              container: (provided) => ({
                ...provided,
                width: '100%',
              }),
              menu: (provided) => ({
                ...provided,
                width: '100%',
              }),
              control: (provided, state) => ({
                ...provided,
                width: '100%',
                minHeight: 45,
              }),
            }} />
            {countryError && <p style={{ color: "red" }}>{countryError}</p>}
          </div>
        </div>
        <div className="bothbuttondiv">
          <button onClick={goPrevious} className="previewbutton">
            {isLoadingpre ? <div className="loader"></div> : <p className="continuetext" style={{ marginTop: 0 }}>Previous</p>}
          </button>
          <button onClick={handleButtonClick} className="nextbutton">
            {isLoading ? <div className="loader"></div> : <p className="continuetext" style={{ marginTop: 0 }}>Next</p>}
          </button>
        </div>
      </div>
      <div className="yellowdiv"></div>
    </div>
  );
};

export default AddressDetails;

