import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Sidebar from './SideMenu';  // Import Sidebar component
import TopHead from './TopHeader';  // Import TopHead component
import './Styles.css';  // Import the new styles for the details container

export default function EnquiryDetails() {
  const { state } = useLocation();
  const { record } = state; // Fetch the passed record details
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/Admin/AllEnquiries'); // Navigate back to the list page
  };

  return (
    <div className="enquiry-page">
      <Sidebar /> {/* Sidebar Component */}
      <div className="main-content">
        <TopHead divStyles={{marginRight: '50px'}}>Enquiry Details</TopHead> {/* TopHead Component */}
        
        <div className="details-container">
          <div className="details-header">Details for {record.title} {record.fname} {record.lname}</div>

          <div className="details-info">
            <h3>Car Registration Details:</h3>
            <p><strong>Registration No:</strong> {record.carnumber}</p>
            <p><strong>Car Make & Model:</strong> {record.carmodal}</p>
            <p><strong>Date:</strong> {record.date}</p>
            <p><strong>Payment:</strong> {record.pay}</p>
            <br></br>
            <h3>Personal Details:</h3>
            <p><strong>Phone:</strong> {record.mobile}</p>
            <p><strong>Email:</strong> {record.email}</p>
            <p><strong>Date of Birth:</strong> {record.selectedDay} / {record.selectedMonth} / {record.selectedYear}</p>
            <br></br>
            <h3>Address Information:</h3>
            <p><strong>Postcode:</strong> {record.postcode}</p>
            <p><strong>Street Address:</strong> {record.streetAddress}</p>
            <p><strong>Address Line:</strong> {record.addressline}</p>
            <p><strong>City:</strong> {record.city}</p>
            <p><strong>Country:</strong> {record.country}</p>
          </div>

          <div className="details-button">
            <button className="details-btn" onClick={handleBack}>Back to Enquiries</button>
          </div>
        </div>
      </div>
    </div>
  );
}
