
import React, { useState, useContext, useEffect } from "react";
import search from "../assets/images/search.png";
import tick from "../assets/images/tick.png";
import wrong from "../assets/images/wrong.png";
import bmw from "../assets/images/bmw-icon.png";
import jaguar from "../assets/images/jaguar-icon.png";
import landrover from "../assets/images/land-rover-icon.png";
import citroen from "../assets/images/citroen-icon.png";
import nissan from "../assets/images/nissan-icon.png";
import iconn from "../assets/images/iconn.png";
import peugeot from "../assets/images/peugeot-icon.png";
import vicon from "../assets/images/v-icon.png";
import car from "../assets/images/car.png";
import time from "../assets/images/time.png";
import brain from "../assets/images/3-brain.png";
import ring from "../assets/images/ring.png";
import man from "../assets/images/man.png";

import tringle from "../assets/images/tringle.png";
import carpicture from "../assets/images/carpicture.jpg";
import logo from "../assets/images/logo.jpg";
import map from "../assets/images/map.png";
import white_piaimg from "../assets/images/white_piaimg.png";

import { useNavigate } from "react-router-dom";
import { FormContext } from "../App";

const Home = () => {
  const navigate = useNavigate();
  const [carnumber, setcarnumber] = useState("");
  const { formData, setFormData } = useContext(FormContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (formData) {
      const { carnumber } = formData;
      setcarnumber(carnumber);
    }

  }, [formData])

  const handleButtonClick = () => {
    if (carnumber) {
      setIsLoading(true);
      setFormData({ ...formData, carnumber });
      console.log('homepageformdata', carnumber);
      setTimeout(() => {
        setIsLoading(false);
        navigate("/RegisterPage", { state: formData });
      }, 1000); 
    }
  };

  return (
    <div className="maincontainer">
      <div className="logodiv">
        <img src={logo} className="logo1" alt="logo" />
      </div>

      <div className="yellowdiv"></div>

      <div
        style={{
          backgroundImage: `url(${carpicture})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "60vh",
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
          position:'relative'
        }}
      >
        <div className="image-text-div">
          <div className="text-container">
          <div className="heading-img">
            <p className="firsttext">You Could Claim Up To  <span className="underline-amount"><img src={white_piaimg} color="#fff" className="homepersonimg" alt="white_piaimg" />10,000 </span>For Each Affeced Vehicle. </p>
          </div>
          <div className="paragraph-img">
            <p className="paragraph-img-text">
              Did you own or lease a new or used diesel vehicle between 2008-2022?
              You could be entitled to compensation due to higher fuel costs, lower reale
              value, plus lots  more, even if you do not own the vehicle anymore!
            </p>
          </div>
          </div>
          <div className="input-div-home">
            <div>
              <p className="enterVehicle">Enter Vehicle Registration Here</p>
            </div>
            <div className="mapInput">
              <div className="mapdiv">
                <img src={map} className="map" alt="map" />
                <p className="GB">GB</p>
              </div>
              <input
                value={carnumber}
                placeholder="Enter Car Number"
                className="input"
                onChange={(e) => setcarnumber(e.target.value.toUpperCase())}
              />
            </div>
            <button className="ContinueButton" disabled={isLoading}
              onClick={() => handleButtonClick()}>
              {isLoading ? <div className="loader"></div> : <p className="continuetextmain">Continue</p>}

            </button>
          </div>
        </div>
      </div>

      <div className="carpicturediv">
      </div>
      <div className="services">
        <div className="inservicesdiv">
          <img src={search} className="icon" alt="search" />
          <h1 className="heading">Check Any Vehicle</h1>
          <p className="heading-text">
            Just enter registration and we'll find and check your vehicle.
          </p>
        </div>
        <div className="inservicesdiv">
          <img src={tick} className="icon" alt="tick" />
          <h1 className="heading">100% No Win, No Fee</h1>
          <p className="heading-text">
            We're 100% upfront about costs. so you'll find no hidden charges.
          </p>
        </div>
        <div className="inservicesdiv">
          <img src={wrong} className="icon" alt="wrong" />
          <h1 className="heading">Quick online decision</h1>
          <p className="heading-text">Our unique system checks your vehicle</p>
        </div>
      </div>

      <div className="seconddiv">
        <div className="firstdiv">
          <p className="first-heading">What is the emissions claim about?</p>
          <h5 className="heading-text2">
            Several leding vehicle manufacturers have been accused of fitting
            'defeat' devices to their vehicles to cheat the EU and UK emissions
            laws
          </h5>
          <p className="seconddiv-text">
            In May 2022, VW Group settled for 193 million with around 91,000
            claimants in England and Wales over the dieselgate scandal, which
            involved under-reporting nitrogen oxide emissions in diesel
            vehicles. Similar claims have been made against VWin the USA and
            Germany. Other manufacturers like Mercedes-Benz,BMW,Ford,and Toyota
            also face litigation for similar issues. Claimants argue they were
            misled into buying vehicles they believed were environmentally
            friendly, leading to financial loss.
          </p>
        </div>
        <div className="anotherdiv">
          <p className="first-heading2">Was Your Vehicle AN "Emission Cheat"?</p>
          <p className="second-div-text">
            Car manufactureres have been accused of fitting defeat devices to
            their vehicles to circumvent EU and UK emissions laws.
          </p>
          <div className="image-div">
            <img className="image" style={{ marginLeft: 0 }} src={bmw} alt="bmw" />
            <img className="image" src={jaguar} alt="jaguar" />
            <img className="image" src={landrover} alt="landrover" />
            <img className="image" src={citroen} alt="citroen" />
          </div>
          <div className="image-div second-div">
            <img className="image" style={{ marginLeft: 0 }} src={nissan} alt="nissan" />
            <img className="image" src={iconn} alt="iconn" />
            <img className="image" src={peugeot} alt="peugeot" />
            <img className="image" src={vicon} alt="vicon" />
          </div>
        </div>
      </div>
      <div className="customersdiv">
        <div className="optionHeading">
          <p className="option-heading-Text">
            Take a look at just some of the reasons why out customers choose us{" "}
          </p>
        </div>
        <div className="services2">
          <div className="inservicesdiv">
            <img src={car} className="icon" alt="car" />
            <h1 className="heading">Claim Specialists</h1>
            <div>
              <p className="heading-text">
                We know the legal landscape inside out and there's no-one with
                more experience and know-how to fight for what's yours.
              </p>
            </div>
          </div>
          <div className="inservicesdiv">
            <img src={time} className="icon" alt="time" />
            <h1 className="heading">Efficient Service</h1>
            <div>
              <p className="heading-text">
                We aim to submit your corrpensation claim in a timely manner.
                Our process is simple and online.
              </p>
            </div>
          </div>
          <div className="inservicesdiv">
            <img src={brain} className="icon" alt="brain" />
            <h1 className="heading">No Hassle</h1>
            <div>
              <p className="heading-text">
                We've got rid of complicated application forms and everything is
                handled from our friendly UK-based office.
              </p>
            </div>
          </div>
        </div>
        <div className="services3">
          <div className="inservicesdiv">
            <img src={ring} className="icon" alt="ring" />
            <h1 className="heading">Maximum Claims</h1>
            <div>
              <p className="heading-text">
                We're committed to getting back every single penny you're owed.
                In some cases up of 10,000.
              </p>
            </div>
          </div>
          <div className="inservicesdiv">
            <img src={man} className="icon" alt="man" />
            <h1 className="heading">Refer & Earn</h1>
            <div>
              <p className="heading-text">
                We've introduced a generous referral scheme for those who spread
                the word and refer new clients to our service.
              </p>
            </div>
          </div>
          <div className="inservicesdiv">
            <img src={tringle} className="icon" alt="tringle" />
            <h1 className="heading">No Hidden Costs</h1>
            <div>
              <p className="heading-text">
                We're 100% upfront about costs, so you'll find no hidden charges
                at any point during your application.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="optionHeading">
          <p className="option-heading-Text">Frequently Asked Questions </p>
        </div>
      </div>
      <div className="footerdiv">
        <div className="footerfirstdiv">
          <div>
            <h3 className="footerheading">Did dieselgate affect my car?</h3>
            <p className="footerp">
              In the UK, there are potentially millions of cars affected with
              dozens of manufacturers installing defest devices. If your diesel
              car was manufacturers installing defeat devices. If your diesel
              car was manufactured betwen 2008 and 2022 you may be eligible to
              join the claim. The car must have been purchased or leased between
              2008 to 2022.
            </p>
          </div>
          <div>
            <h3 className="footerheading">
              I'm interested. How do i register?
            </h3>
            <p className="footerp">
              Simply fill out our eligibility checker to see if you are eligible
              for compensation for the emissions scandal.
            </p>
          </div>
          <div>
            <h3 className="footerheading">Why should I make a claim?</h3>
            <p className="footerp">
              Your car manufacturer lied to you about a very dangerous
              pollutant,NOx. Car giants should be held to account and told they
              cannot put profits above our health and the environment In
              addition, you could receive componsation that is worth thousands
              of pounds,depending on the circumstances of you case.
            </p>
          </div>
        </div>
        <div className="footerfirstdiv">
          <div>
            <h3 className="footerheading">
              What is the diesel emissions claim about?
            </h3>
            <p className="footerp">
              We represent UK residents whose cars have been fitted with defeat
              devices by manufacturers. As a result, emissions tests thought
              their emissions were lower than they really were. As NOx released
              by their cars contaminate our air and cause harm to our health,
              this is unacceptable. Financial damages may also have been
              incurred.
            </p>
          </div>
          <div>
            <h3 className="footerheading">What happens if I lose my claim?</h3>
            <p className="footerp">
              If you lose your emissions claim, we will not charge you. This is
              due to our No Win, No Fee policy.
            </p>
          </div>
          <div>
            <h3 className="footerheading">
              What if my car is fixed by the manufacturer?
            </h3>
            <p className="footerp">
              It shouldn't affect your claim. Many people who had this "fix"
              later discovered their car's performance suffered and their fuel
              consumption increased. Please tell us if your car was recalled and
              had a fix when submitting a claim using our online form.
            </p>
          </div>
        </div>
      </div>
      <div className="yellowdiv"></div>
    </div>
  );
};
export default Home;

